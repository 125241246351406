import React from 'react'
import times from 'lodash/times'
import styled from '@emotion/styled'
import { Flex, Box, Text } from 'rebass'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const StyledImg = styled(Img)`
  border-radius: inherit;
`

const Review = ({ review, reviewImage }) => {
  const data = useStaticQuery(graphql`
    {
      starImage: file(relativePath: { eq: "images/star.png" }) {
        childImageSharp {
          fixed(width: 15) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return (
    <Box
      sx={{
        marginTop: [0, 0, 80],
        position: 'relative'
      }}
    >
      <Box
        sx={{
          top: 0,
          left: 0,
          width: 425,
          fontSize: '0px',
          marginTop: -50,
          boxShadow: 'card',
          position: 'absolute',
          borderRadius: 'default',
          display: ['none', 'none', 'block'],
        }}
        bg="img"
      >
        {reviewImage && <StyledImg fixed={reviewImage} />}
      </Box>
      <Flex
        p={[4, 4, 5]}
        pt={[4, 4, 4]}
        pb={[4, 4, 5]}
        pr={[4, 4, 5]}
        pl={[4, 4, 300]}
        ml={[0, 0, 230]}
        fontSize={3}
        bg="bg.gray"
        color="text.default"
        sx={{
          borderRadius: 'default'
        }}
        flexDirection="column"
      >
        <Flex
          sx={{
            minHeight: 180
          }}
          justifyContent="center"
          flexDirection="column"
        >
          <Flex flexDirection="row">
            {times(5, (i) => <Img fixed={data.starImage.childImageSharp.fixed} key={i} />)}
          </Flex>
          <p>{review.text}</p>
        </Flex>

        <Box mt={4}>
          <Text fontSize={2} fontWeight="bold">
            - {review.authorName}
          </Text>
        </Box>
      </Flex>
    </Box>
  )
}

export default Review
