import React from 'react'
import { Flex, Box, Heading, Text } from 'rebass'
import { ExternalLink } from './TextLink'

const ContactLarge = ({ translations: t = {}, contactInfo = {} }) => {
  return (
    <Box px={4}>
      <Flex
        mt={4}
        mx={-4}
        flexWrap="wrap"
        flexDirection="row"
      >
        <Box px={4} pb={4} width={1}>
          <Heading as="h3" mb={2} color="heading.dark">{t.section.contact.address}</Heading>
          <Text maxWidth={150}>
            <ExternalLink href={`https://google.com/maps?q=${encodeURIComponent(contactInfo.address)}`}>
              {contactInfo.address}
            </ExternalLink>
          </Text>
        </Box>
        <Box px={4} pb={4} width={1}>
          <Heading as="h3" mb={2} color="heading.dark">{t.section.contact.email}</Heading>
          <ExternalLink color="inherit" href={`mailto:${contactInfo.emailAddress}`}>{contactInfo.emailAddress}</ExternalLink>
        </Box>
        <Box px={4} pb={4} width={1}>
          <Heading as="h3" mb={2} color="heading.dark">{t.section.contact.phone}</Heading>
          <ExternalLink href={`tel:${contactInfo.phoneNumber}`}>{contactInfo.phoneNumber}</ExternalLink>
        </Box>
      </Flex>
    </Box >
  )
}

export default ContactLarge
