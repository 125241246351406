import React from 'react'
import { Flex, Box } from 'rebass'
import Map from './Map'
import ContactLarge from './ContactLarge'

const Contact = ({ contactInfo = {}, translations = {} }) => {
  return (
    <Flex
      mx={-3}
      flexWrap="wrap"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        width={[1, 1, 2 / 3]}
      >
        <Map
          lat={47.633590}
          lng={20.754560}
          title="Pusztaház Apartmanok"
          address="5350 Tiszafüred, Sárgarigó u. 25-27."
          query="Pusztahaus Tiszafüred"
        />
      </Box>
      <Box p={3} flex={1}>
        <ContactLarge
          contactInfo={contactInfo}
          translations={translations}
        />
      </Box>
    </Flex>
  )
}

export default Contact
