import React from 'react'
import styled from '@emotion/styled'
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'
import LazyLoad from './LazyLoad'

const StyledContainer = styled('div')`
  border: 0;
  width: 100%;
  height: 350px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.divider};
`

const StyledPlaceCard = styled('div')`
  position: absolute;
  left: 0;
  top: 0;
  margin: 10px;
  color: black;
  background: white;
  min-width: 200px;
  border-radius: 2px;
  padding: 10px 26px 10px 12px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
`

const Title = styled('div')`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 14px;
  color: black;
`

const Address = styled('div')`
  font-size: 12px;
  margin-top: 6px;
`

const MapLink = styled('a')`
  font-size: 12px;
  text-decoration: none;
  display: block;
  margin-top: 20px;
  color: ${props => props.theme.colors.brand};
`

function MapEmbed({ lat, lng, title, address, query, google }) {
  return (
    <LazyLoad height={300} once>
      <StyledContainer id="mapcont">
        <Map
          zoom={16}
          google={google}
          initialCenter={{ lat, lng }}
        >
          <Marker
            alt={title}
            name={title}
            position={{ lat, lng }}
          />
          <StyledPlaceCard>
            <Title
              dangerouslySetInnerHTML={{
                __html: title
              }}
            />
            <Address
              dangerouslySetInnerHTML={{
                __html: address
              }}
            />
            <MapLink
              target="_blank"
              rel="noopener noreferrer"
              title="Megnyitás Google Maps-en"
              href={`https://www.google.com/maps?mapclient=embed&q=${encodeURI(query)}`}
            >
              Megnyitás
          </MapLink>
          </StyledPlaceCard>
        </Map>
      </StyledContainer>
    </LazyLoad>
  )
}

export default GoogleApiWrapper({
  apiKey: process.env.GOOGLE_API_KEY
})(MapEmbed)
