import React from 'react'
import { Flex, Box, Text } from 'rebass'
import ListItem from './ListItem'

const Amenity = ({ name = '', availability = '' }) => {

  return (
    <ListItem
      px={4}
      py={2}
      width={[1, 1, 1 / 2]}
      sx={{
        whiteSpace: 'nowrap'
      }}
    >
      <Flex
        flexDirection="row"
        alignItems="flex-end"
      >
        <Text>{name}</Text>
        <Box
          flex={1}
          mx={2}
          as="hr"
          sx={{
            border: 0,
            opacity: .5,
            borderTop: '1px solid',
            borderColor: 'white'
          }}
        />
        <Text fontWeight="bold">{availability}</Text>
      </Flex>
    </ListItem>
  )
}

export default Amenity
