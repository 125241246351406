import React from 'react'
import styled from '@emotion/styled'
import { Flex, Box, Text } from 'rebass'
import htmlParser from 'html-react-parser'
import Img from 'gatsby-image'

const StyledImg = styled(Img)`
  max-width: 100%;
  border-radius: ${props => props.theme.radii.default}px;
`

const AboutUs = ({ description, aboutUsImage }) => {
  return (
    <Flex
      mx={-3}
      flexWrap="wrap"
      flexDirection="row"
      alignItems="center"
    >
      <Box p={[0, 3]} textAlign={['center', 'center', 'left']} width={[1, 1, 'auto']}>
        <StyledImg fixed={aboutUsImage} />
      </Box>
      <Box p={3} flex={1}>
        <Text as="div" color="text.default">
          {htmlParser(description)}
        </Text>
      </Box>
    </Flex>
  )
}

export default AboutUs
