import React from 'react'
import styled from '@emotion/styled'
import { Flex, Box, Heading } from 'rebass'
import Img from 'gatsby-image'
import List from './List'
import Amenity from './Amenity'
import ImgOverlay from './ImgOverlay'

const FullImg = styled(Img)`
  width: 100%;
  height: 100%;
  opacity: .8;
`

const Amenities = ({ translations: t = {}, amenitiesImage }) => {

  return (
    <Flex
      bg="img"
      sx={{
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          flex: 1,
          maxWidth: 800,
          margin: '0 auto',
          zIndex: 3,
          position: 'relative',
        }}
      >
        <Box
          p={4}
          py={5}
          bg="brand.1"
          color="white"
        >
          <Heading
            as="h2"
            mb={4}
            fontSize={[6, 7]}
            variant="sectionHead"
          >
            {t.title}
          </Heading>

          <List
            pt={3}
            mx={-4}
            flexWrap="wrap"
            flexDirection="row"
          >
            <Amenity name={t.parking} availability={t.optionHas} />
            <Amenity name={t.pool} availability={t.optionHas} />
            <Amenity name={t.outdoorCooking} availability={t.optionHas} />
            <Amenity name={t.playground} availability={t.optionHas} />
            <Amenity name={t.terrace} availability={t.optionHas} />
            <Amenity name={t.kitchen} availability={t.optionHas} />
            <Amenity name={t.separateToilets} availability={t.optionHas} />
            <Amenity name={t.tv} availability={t.optionHas} />
            <Amenity name={t.petFriendly} availability={t.optionYes} />
            <Amenity name={t.wifi} availability={t.optionHas} />
          </List>
        </Box>
      </Box>

      <ImgOverlay>
        <FullImg fluid={amenitiesImage} />
      </ImgOverlay>
    </Flex>
  )
}

export default Amenities
