import React from 'react'
import OriginalLazyLoad from 'react-lazyload'

const LazyLoad = ({ children, ...props }) => {
  const isSSR = typeof window === 'undefined'

  return isSSR
    ? children
    : (
      <OriginalLazyLoad
        {...props}
      >
        {children}
      </OriginalLazyLoad>
    )
}

export default LazyLoad
