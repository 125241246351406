import React from 'react'
import Slider from 'react-slick'
import styled from '@emotion/styled'
import { Box } from 'rebass'

import Review from './Review'
import List from './List'
import ListItem from './ListItem'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const carouselSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  draggable: false,
}

const StyledContainer = styled(Box)`
  overflow: hidden;

  .slick-dots{
    margin:0;
    list-style:none;
    position: absolute;
    top: 0;
    right: ${props => props.theme.space[4]}px;
    left: auto;
    bottom: auto;
    width: auto;
  }

  .slick-dots button:before {
    font-size: 12px;
  }

  .slick-dots .slick-active button:before {
    color: ${props => props.theme.colors.brand[0]};
  }
`

const ReviewList = ({ reviews = [], reviewImages = [] }) => {
  return (
    <>
      <List display={['block', 'block', 'none']}>
        {reviews.map((review, i) => (
          <ListItem
            key={i}
            my={2}
            sx={{
              outline: 0
            }}
          >
            <Review
              review={review.node}
            />
          </ListItem>
        ))}
      </List>
      <StyledContainer display={['none', 'none', 'block']}>
        <Slider
          {...carouselSettings}
        >
          {reviews.map((review, i) => (
            <Box
              px={4}
              key={i}
            >
              <Review
                review={review.node}
                reviewImage={reviewImages[i].node.childImageSharp.fixed}
              />
            </Box>
          ))}
        </Slider>
      </StyledContainer>
    </>
  )
}

export default ReviewList
